
import Vue from 'vue'
import { mapState } from 'vuex'
import Button from '@/components/common/buttons/Button.vue'

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { Button },
  computed: {
    ...mapState('DeviceStore', ['deviceInfo']),
    ...mapState('UserStore', ['user']),
  },
  methods: {
    goWithdrawalPage() {
      this.$tracking('mypage_info_signout')
      this.$router.push('/mypage/withdrawal')
    },
    goNaverUserModify() {
      this.$tracking('mypage_info_edit')
      try {
        const payload = {
          url: 'https://nid.naver.com/user2/help/myInfoV2?lang=ko_KR',
          title: '네이버 ID',
        }
        this.$rendingOutsidePage(this.$device, payload)
      } catch (e) {
        console.log('naver subWebView err')
      }
    },
  },
})
interface Data {}
interface Methods {
  goWithdrawalPage(): void
  goNaverUserModify(): void
}
interface Computed {
  user: UserProfileGroup
  deviceInfo: DeviceInfo
}
interface Props {}
