
import Vue from 'vue'
import Info from '@/components/views/mypage/Info.vue'

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { Info },
})
interface Data {}
interface Methods {}
interface Computed {}
interface Props {}
